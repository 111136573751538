import './App.css';

function App() {
  return (
    <div>
      <h1 className='sketch-font'>What's going on with Timothy?</h1>
    </div>
  );
}

export default App;
